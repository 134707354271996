<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <TheInscriptionTable />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Formularios",
  components: {
    TheInscriptionTable: () =>
      import(
        "@/components/dashboardComponents/TheInscriptionTable"
      ),
  },
  data: () => ({
    page: {
      title: "Formulario"
    },
    elementVisible: false,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/dashboard/index/"
      },
      {
        text: "Formularios",
        disabled: true,
        href: "/dashboard/inscriptions/"
      }
    ]
  })
};
</script>
